.skills {
  animation: floatText 10s infinite linear;
  /* animation: 10s linear 0s infinite normal none running floatText; */
  overflow-y: scroll;
}

.skills:hover {
  animation-play-state: paused;
}

@media screen and (min-width: 1480px)  {
  @keyframes floatText {
    to {
      transform: translateX(-82vw);
    }
  }
}

@media screen and (max-width: 1480px)  {
  @keyframes floatText {
    to {
      transform: translateX(-168vw);
    }
  }
}

/* @keyframes floatText {
  to {
    transform: translateX(-120vw);
  }
} */